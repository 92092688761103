console.group("inicializando o pack de imagens do app")



import '/var/www/apps/apl/app/frontend/images/brand/app_logo_full.png';

import '/var/www/apps/apl/app/frontend/images/brand/app_logo_vert.png';

import '/var/www/apps/apl/app/frontend/images/brand/app_symbol.png';

import '/var/www/apps/apl/app/frontend/images/brand/app_text.png';


console.groupEnd();
